import { CardEditorialSlideshow, TagManager } from '@accor/ace-ui-core';

export default class MantraCardEditorialSlideshow extends CardEditorialSlideshow {
  /** @inheritDoc */
  constructor(componentHost, componentName) {
    super(componentHost, componentName);
    this.bindTagmanager();
  }

  /**
     * set Event listener for the GA event
     */
  bindTagmanager() {
    const dataLayer = document.querySelector('[data-cmp-datalayer]')?.getAttribute('data-cmp-datalayer');
    this.componentHost?.querySelectorAll('.cmp-slideshow__callout-section .cmp-slideshow__callout-item')?.forEach((element) => {
      element?.querySelector('a')?.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
        let eventDataVariable = {
          pagename: 'homepage',
          bloc_name: 'discover all',
          bloc_interaction: 'loyalty programme'
        };
        const datalayerValue = JSON.parse(dataLayer);
        const page = datalayerValue.pageName.split('::')[1];
        let eventNameVariable = 'bloc_interact';
        if (dataLayer && document.body.classList.contains('homepage')) { // Homepage
          eventDataVariable = eventDataVariable;
        } else if (page == 'business') { // Business page
          eventDataVariable = {
            pagename: datalayerValue.pageName,
            bloc_name: element?.querySelector('.cmp-slideshow__callout-title')?.innerText?.toLowerCase()?.trim(),
            bloc_interaction: 'find out more'
          };
        } else if (page == 'events') { // Events page
          eventDataVariable = {
            pagename: datalayerValue.pageName,
            bloc_name: element?.querySelector('.cmp-slideshow__callout-title')?.innerText?.toLowerCase()?.trim(),
            bloc_interaction: 'find out more'
          };
        } else if (datalayerValue.pageName == 'mantra::list::golf') { // Loyalty page
          eventDataVariable = {
            pagename: datalayerValue.pageName,
            enroll_context: 'loyalty program',
            cta_name: 'join us'
          };
          eventNameVariable = 'cta_enroll';
        }
        // Push data to datalayer
        const datasEvent = {
          event: 'GA4event',
          eventName: eventNameVariable,
          event_data: eventDataVariable
        };
        TagManager.trackEvent(datasEvent);
      });
    });
  }
}

// Registering component in component factory.
CoreJS.BaseComponent.registerComponent(CardEditorialSlideshow.CLASS_NAMESPACE, MantraCardEditorialSlideshow, true);
