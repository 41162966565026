import { CalloutContainer, TagManager } from '@accor/ace-ui-core';

export default class MantraCalloutContainer extends CalloutContainer {
  /** @inheritDoc */
  constructor(componentHost, componentName) {
    super(componentHost, componentName);
    this.bindTagmanager();
  }

  bindTagmanager() {
    const layerElement = document.querySelector('[data-cmp-datalayer]');
    const dataLayer = layerElement?.getAttribute('data-cmp-datalayer');
    if (dataLayer) {
      const datalayerValue = JSON.parse(dataLayer);
      const page = datalayerValue.pageName.split('::')[2];
      const bookingHotelPage = this.componentHost.querySelectorAll('.ace-callout-component');
      bookingHotelPage.forEach((item) => {
        let hotelName = item.querySelector('.cmp-teaser__pretitle')?.innerText?.toLowerCase()?.trim();
        let offerName = item.querySelector('.cmp-teaser__kicker')?.innerText?.toLowerCase()?.trim() ? item.querySelector('.cmp-teaser__kicker')?.innerText?.toLowerCase()?.trim() : '';
        let { pageName } = datalayerValue;
        let blockName = '';
        let eventDataVariable = {
          pagename: pageName,
          bloc_interaction: hotelName,
          bloc_type: offerName
        };
        if (page == 'offers hub') { // offer hub
          pageName = datalayerValue.pageName;
          blockName = 'all mantra offers';
          offerName = 'cta';
          eventDataVariable = {
            pagename: pageName,
            bloc_name: blockName,
            bloc_interaction: hotelName,
            bloc_type: offerName
          };
        } else if (page == 'offers details page') { // offer
          pageName = datalayerValue.pageName;
          blockName = 'our selection';
          eventDataVariable = {
            pagename: pageName,
            bloc_name: blockName,
            bloc_interaction: hotelName,
            bloc_type: offerName
          };
        } else if (document.body.classList.contains('homepage')) { // home
          pageName = 'homepage';
          blockName = 'offers section';
          eventDataVariable = {
            pagename: pageName,
            bloc_name: blockName,
            bloc_interaction: hotelName,
            bloc_type: offerName
          };
        } else if (datalayerValue.pageName.split('::')[1] == 'long stay') { // Long stay
          pageName = datalayerValue.pageName;
          blockName = item.closest('.callout-container')?.previousElementSibling?.querySelector('.ace-headingpagehero-headline h2')?.innerText?.toLowerCase()?.trim() ?
            item.closest('.callout-container')?.previousElementSibling?.querySelector('.ace-headingpagehero-headline h2')?.innerText?.toLowerCase()?.trim() :
            item.closest('.callout-container')?.previousElementSibling?.querySelector('.ace-headingpagehero-headline h3')?.innerText?.toLowerCase()?.trim();
          eventDataVariable = {
            pagename: pageName,
            bloc_name: blockName,
            bloc_interaction: hotelName,
            bloc_type: offerName
          };
        } else if (datalayerValue.pageName == 'mantra::hotelsandresorts::destinations::homepage') { // Hotel and Resort
          const country = item.closest('.callout-container').previousElementSibling?.querySelector('.ace-headingpagehero-headline')?.innerText?.toLowerCase()?.trim();
          const cityName = item.querySelector('.cmp-teaser__pretitle a')?.innerText?.toLowerCase()?.trim();
          pageName = datalayerValue.pageName;
          hotelName = 'city';
          offerName = '';
          blockName = `${country}_${cityName}`;
          eventDataVariable = {
            pagename: pageName,
            bloc_name: blockName,
            bloc_interaction: hotelName
          };
        } else { // Destination city and state
          blockName = hotelName;
          hotelName = '';
          offerName = '';
          eventDataVariable = {
            pagename: pageName,
            bloc_name: blockName,
            bloc_interaction: datalayerValue?.pageName?.split('::')[3]
          };
        }
        item.addEventListener(CoreJS.DomEventConstants.CLICK, (event) => {
          const datasEvent = {
            event: 'GA4event',
            eventName: 'bloc_interact',
            event_data: eventDataVariable
          };
          // eslint-disable-next-line no-undef
          TagManager.trackEvent(datasEvent);
          event.stopImmediatePropagation();
        });
      });
    }
  }
}

// Registering component in component factory.
CoreJS.BaseComponent.registerComponent(CalloutContainer.CLASS_NAMESPACE, MantraCalloutContainer, true);
