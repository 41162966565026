import { TagManager, NavBar } from '@accor/ace-ui-core';

export default class MantraNavBar extends NavBar {
  /** @inheritDoc */
  constructor(componentHost, componentName) {
    super(componentHost, componentName);
    window.dataLayer.push({ 'page_data': null });

    this.user_is_logged = 0;
    if (CoreJS.CommonUtils.userLoginStatus()) {
      this.user_is_logged = 1;
    }
    this.navigationSelectors();
    this.bindEvents();
    this.tabMenuNavigation();
  }

  /*
      Function for selectors
    */
  navigationSelectors() {
    this.dataLayer = document.querySelector('[data-cmp-datalayer]')?.getAttribute('data-cmp-datalayer');
    this.dataLayerValue = JSON.parse(this.dataLayer);

    this.logo = this.componentHost.querySelector('.ace-header__logo--link');
    this.navItem = this.componentHost.querySelectorAll('.nav-item ');

    this.myAccountButton = this.componentHost.querySelectorAll('.ace-myaccount__header button');
    this.myAccountShow = this.componentHost.querySelectorAll('.ace-myaccount .button');
    this.myAccountItem = this.componentHost.querySelectorAll('.ace-myaccount__item');
  }

  /*
      Function to bind click events
    */
  bindEvents() {
    // Logo
    this.logo?.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
      const data = {
        event: 'GA4event',
        eventName: 'bloc_interact',
        event_data: {
          pageName: this.dataLayerValue?.pageName,
          bloc_name: 'logo mantra',
          bloc_interaction: 'header'
        }
      };
      this.tagManagerHandler(data);
    });

    // Navigation item
    this.navItem?.forEach((item, index) => {
      item.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
        const data = {
          event: 'GA4event',
          eventName: 'menu_interact',
          event_data: {
            pageName: this.dataLayerValue?.pageName,
            menu_position: index + 1,
            menu_label: item.querySelector('.nav-link').innerText?.toLowerCase()?.trim()
          }
        };
        this.tagManagerHandler(data);
      });
    });

    // My account
    this.myAccountButton?.forEach((item) => {
      item.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
        const data = {
          event: 'GA4event',
          eventName: 'user_connect_open',
          event_data: {
            pageName: this.dataLayerValue?.pageName,
            user_is_logged: this.user_is_logged
          }
        };
        this.tagManagerHandler(data);
      });
    });


    // Sign in
    this.myAccountShow?.forEach((item, index) => {
      item.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
        const data = {
          event: 'GA4event',
          eventName: 'user_connect_interact',
          event_data: {
            pageName: this.dataLayerValue?.pageName,
            user_is_logged: this.user_is_logged,
            menu_position: index + 1,
            menu_label: item.querySelector('.ace-myaccount-btn .cmp-button__text').innerText?.toLowerCase()?.trim()
          }
        };
        this.tagManagerHandler(data);
      });
    });

    // Login actions
    this.myAccountItem?.forEach((item) => {
      item.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
        let menuPosition = 3;
        if (item.querySelector('a')?.innerText?.trim() == 'Log Out') {
          menuPosition = 4;
        } else if (item.querySelector('a').innerText?.trim() == 'My account') {
          menuPosition = 5;
        } else if (item.querySelector('a').innerText?.trim() == 'Use your points') {
          menuPosition = 6;
        }
        const data = {
          event: 'GA4event',
          eventName: 'user_connect_interact',
          event_data: {
            pageName: this.dataLayerValue?.pageName,
            user_is_logged: this.user_is_logged,
            menu_position: menuPosition,
            menu_label: item.querySelector('a').innerText?.toLowerCase()?.trim()
          }
        };
        this.tagManagerHandler(data);
      });
    });
  }

  tagManagerHandler(data) {
    window.dataLayer.push({ 'page_data': null });
    TagManager.trackEvent(data);
  }

  /*
      Function to trap navigation menu and menu item navigation
    */
  tabMenuNavigation() {
    const navBarLogo = this.componentHost.querySelector('.ace-header__logo--image');
    const navBarMyaccount = this.componentHost.querySelector('.ace-myaccount__header button');
    if (window.innerWidth < CoreJS.ResponsiveConstants.GRID_BREAKPOINTS.lg) {
      // On Focusin of my account close menu panel
      navBarMyaccount?.addEventListener(CoreJS.DomEventConstants.FOCUS_IN, (event) => {
        if (this.componentHost.querySelector('.navbar-toggler')?.getAttribute('aria-expanded') === 'true') {
          event.preventDefault();
          this.componentHost.querySelector('.navbar-toggler')?.setAttribute('aria-expanded', 'false');
          this.componentHost.querySelector('.navbarContent')?.setAttribute('aria-hidden', 'true');
        }
      });

      // logo tab behaviour
      navBarLogo?.addEventListener(CoreJS.DomEventConstants.KEY_DOWN, (event) => {
        if (event.key === CoreJS.Constants.KEYBOARD_EVENT_KEYS.tab) {
          navBarLogo?.setAttribute('tabindex', '-1');
          if (!event.shiftKey) {
            navBarMyaccount?.focus();
          }
        }
      });

      // my account shift tab behaviour
      navBarMyaccount?.addEventListener(CoreJS.DomEventConstants.KEY_DOWN, (event) => {
        if (event.key === CoreJS.Constants.KEYBOARD_EVENT_KEYS.tab && event.shiftKey) {
          navBarLogo?.setAttribute('tabindex', '0');
          navBarLogo?.focus();
        }
      });
    }
  }
}

// Registering component in component factory.
CoreJS.BaseComponent.registerComponent(NavBar.CLASS_NAMESPACE, MantraNavBar, true);
