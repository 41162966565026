/**
 * @author Rishabh
 * @class Billboard
 * @version 1.0
 * @inheritDoc
 */
import { Billboard, TagManager } from '@accor/ace-ui-core';

export default class MantraBillboard extends Billboard {
  /** @inheritDoc */

  /**
     * set Event listener for the GA event
     */
  setBillboardClickListener() {
    const dataLayer = document.querySelector('[data-cmp-datalayer]')?.getAttribute('data-cmp-datalayer');
    if (dataLayer && document.body.classList.contains('homepage')) {
      this.componentHost?.querySelector('.ace-button a')?.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
        // Push data to datalayer
        const datasEvent = {
          event: 'GA4event',
          eventName: 'bloc_interact',
          event_data: {
            pagename: 'homepage',
            bloc_name: 'discover all',
            bloc_interaction: 'loyalty programme'
          }
        };
        TagManager.trackEvent(datasEvent);
      });
    }
  }
}

// Registering component in component factory.
CoreJS.BaseComponent.registerComponent(Billboard.CLASS_NAMESPACE, MantraBillboard, true);
